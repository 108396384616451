import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import { SEO } from "../components/SEO";

// eslint-disable-next-line
export const StaffPageTemplate = ({
  title,
  image,
  heading,
  description,
  members,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="has-text-weight-semibold is-size-2">{heading}</h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div id="partners" className="container staff-members">
          <div id="david-siegrist" className="staff-member">
            <div className="columns is-align-items-center">
              <div className="column is-6">
                <PreviewCompatibleImage
                  imageInfo={members.partners.david}
                  alt={members.partners.david.alt}
                  imageStyle={{ borderRadius: "0.5rem" }}
                />
              </div>
              <div className="column is-6">
                <h3 className="has-text-primary is-size-3">
                  David J. Siegrist
                </h3>
                <h4>Overview</h4>
                <p>{members.partners.david.overview}</p>
                <h4>Education</h4>
                <ul>
                  <li>University of Illinois: B.S. (1978)</li>
                  <li>Drake University: Juris Doctorate (1981)</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="brian-jones" className="staff-member">
            <div className="columns is-align-items-center">
              <div className="column is-6">
                <h3 className="has-text-primary is-size-3">Brian D. Jones</h3>
                <h4>Overview</h4>
                <p>{members.partners.brian.overview}</p>
                <h4>Primary Practice Areas</h4>
                <ul>
                  <li>
                    Business or corporation, including:
                    <ul>
                      <li>entity formation</li>
                      <li>business purchases and sales</li>
                      <li>non-profit entities</li>
                    </ul>
                  </li>
                  <li>Tax planning and return preparation</li>
                  <li>Real estate title and transactions</li>
                  <li>Estate planning, wills, and probate</li>
                  <li>Trust planning and trust administration</li>
                  <li>Collections and foreclosure</li>
                  <li>Adoption</li>
                </ul>
                <h4>Education</h4>
                <ul>
                  <li>
                    Iowa State University of Science and Technology: B.A. (1986)
                  </li>
                  <li>
                    University of Iowa College of Law: Juris Doctorate (1990)
                  </li>
                </ul>
              </div>
              <div className="column is-6">
                <PreviewCompatibleImage
                  imageInfo={members.partners.brian}
                  alt={members.partners.brian.alt}
                  imageStyle={{ borderRadius: "0.5rem" }}
                />
              </div>
            </div>
          </div>
          <div id="thomas-lipps" className="staff-member">
            <div className="columns is-align-items-center">
              <div className="column is-6">
                <PreviewCompatibleImage
                  imageInfo={members.partners.thomas}
                  alt={members.partners.thomas.alt}
                  imageStyle={{ borderRadius: "0.5rem" }}
                />
              </div>
              <div className="column is-6">
                <h3 className="has-text-primary is-size-3">Thomas W. Lipps</h3>
                <h4>Overview</h4>
                <p>
                  Thomas W. Lipps has successfully handled landmark cases, won
                  multimillion-dollar jury verdicts, and settled record-setting
                  settlements. He also handles estates, trusts, wills, probate,
                  and estate planning matters.
                  <br />
                  <br />
                  Mr. Lipps represented the plaintiffs, who obtained a $33
                  million jury verdict in an environmental pollution
                  case—reportedly a national record.
                  <br />
                  <br />
                  He also represented the plaintiff in a jury trial, recovering
                  what still stands as the highest verdict in Kossuth County
                  history for a wrongful death case as well as what was reported
                  as the highest punitive damage award in Kossuth County
                  District Court history.
                  <br />
                  <br />
                  Mr. Lipps, in addition to being licensed in all state and
                  federal courts, is qualified as a board-certified civil trial
                  advocate by the National Board of Trial Advocacy and a Fellow
                  of the Iowa Academy of Trial Lawyers. Mr. Lipps is also a
                  member of the Iowa Association for Justice (IAJ) and the
                  American Association for Justice (AAJ). Mr. Lipps received the
                  2000 Public Justice Award from the Iowa Association for
                  Justice for holding a poultry factory farm accountable for
                  pollution. He has also handled, briefed, and/or argued several
                  significant Iowa appellate decisions.
                </p>
              </div>
            </div>
          </div>
          <div id="steve-bakke" className="staff-member">
            <div className="columns is-align-items-center">
              <div className="column is-6">
                <h3 className="has-text-primary is-size-3">Steven R. Bakke</h3>
                <h4>Overview</h4>
                <p>{members.partners.steve.overview}</p>
                <h4>Professional Service</h4>
                <ul>
                  <li>Member of the Iowa Bar</li>
                  <li>2A District Bar (President)</li>
                  <li>Winnebago County Bar Association (President)</li>
                  <li>Past Member of the 2A Judicial Nominating Committee</li>
                  <li>
                    Member of the Iowa Municipal Attorney Association (Board
                    Member, President 2013)
                  </li>
                </ul>
                <h4>Public Service</h4>
                <ul>
                  <li>
                    50-year-old member of the Forest City Rotary Club, President
                    76-77, recipient of the Rotary of the Year.
                  </li>
                  <li>
                    Co-chair of committees that built the tennis courts,
                    skateboard courts, and aquatic center in Boman Park
                  </li>
                  <li>
                    Recipient of the Forest City Chamber of Commerce John K. and
                    Louise V. Hanson Lifetime Achievement Award.
                  </li>
                </ul>
                <h4>Education</h4>
                <ul>
                  <li>Waldorf College: A.A.</li>
                  <li>University of North Iowa: B.A.</li>
                  <li>Drake Law School: J.D.</li>
                </ul>
              </div>
              <div className="column is-6">
                <PreviewCompatibleImage
                  imageInfo={members.partners.steve}
                  alt={members.partners.steve.alt}
                  imageStyle={{ borderRadius: "0.5rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

StaffPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  members: PropTypes.shape({
    partners: PropTypes.array,
  }),
};

const StaffPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <StaffPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        members={frontmatter.members}
      />
    </Layout>
  );
};

StaffPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default StaffPage;

export const staffPageQuery = graphql`
  query StaffPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        members {
          partners {
            david {
              overview
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            brian {
              overview
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            steve {
              overview
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            thomas {
              overview
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => (
  <SEO
    title={data.markdownRemark.frontmatter.title}
    description={data.markdownRemark.frontmatter.description}
  />
);
